import { Component } from '@angular/core';
import * as moment from 'moment-timezone';


@Component({
  selector: 'app-event-component',
  templateUrl: './event.component.html'
})
export class EventComponent {
  public getLocalTime(day: number, calgaryTime: string): string {
    let time = moment().day(day).format('YYYY-MM-DD');
    let calgaryMoment = moment.tz(`${time} ${calgaryTime}`, 'America/Edmonton');
    let locationGuess = moment.tz.guess();
    let mom = calgaryMoment.utc().tz(locationGuess);
    let utcOffset = mom.utcOffset();
    return calgaryMoment.utc().tz(locationGuess).format('ha z') + ' GMT' + utcOffset/60 + 'h';
    // return jun.tz(moment.tz.guess()).format('ha z');  // 5am PDT
  }

}
