import { Injectable } from '@angular/core';
import { createClient, Entry, EntryCollection } from 'contentful';
import { Agenda } from '../agenda/agenda';

const CONFIG = {
  space: 'wgz7so7klal8',
  accessToken: '_uluoFa16lhZgGDm6oadDGket59ti4wzYWDtoM34obU',

  contentTypeIds: {
    agenda: 'agenda',
    weeklyMeetingAgenda: 'timeagenda'
  }
}

@Injectable({
  providedIn: 'root'
})
export class ContentfullService {

  private cdaClient = createClient({
    space: CONFIG.space,
    accessToken: CONFIG.accessToken
  });

  constructor() { }

  getAgenda(query?: object): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries<any>(Object.assign({
      content_type: CONFIG.contentTypeIds.agenda
    }, query))
      .then(res => res.items);
  }

  getAgendaById(id: string): Promise<Entry<any>[]> {
    return this.cdaClient.getEntries<any>({
      content_type: CONFIG.contentTypeIds.agenda,
      'fields.id': id
    })
      .then(res => res.items);
  }
}
