import { Component, OnInit } from '@angular/core';
import { ContentfullService } from '../services/contentfull.service';
import { Entry } from 'contentful';
import { Agenda } from './agenda';
import * as marked from 'marked';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {

  public agendaList: Entry<any>;
  public agenda: any;

  constructor(private route: ActivatedRoute, private contentSvc: ContentfullService) { }

  ngOnInit() {
    const agendaId = this.route.snapshot.paramMap.get('id');
    this.contentSvc.getAgendaById(agendaId)
      .then(products => 
        {
          this.agenda = products[0].fields;
          this.agenda.agendaList = this.agenda.agendaList.map(
            timeslot => {
              let ts = timeslot.fields;
              ts.detail = marked(<string>(ts.detail));
              return ts;    
            }
          );
        });
  }

}
